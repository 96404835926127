import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { IconFaceSmile } from 'hds-react';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/icon/customisation",
  "title": "Icon - Customisation"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Customising icons is rather simple. Use the `}<inlineCode parentName="p">{`size`}</inlineCode>{` property to control size and the `}<inlineCode parentName="p">{`color`}</inlineCode>{` property to control colour.`}</p>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
<IconFaceSmile size="xs" color="var(--color-bus)" aria-hidden="true" />
<IconFaceSmile size="s" color="var(--color-brick)" aria-hidden="true" />
<IconFaceSmile size="m" color="var(--color-coat-of-arms)" aria-hidden="true" />
<IconFaceSmile size="l" color="var(--color-tram)" aria-hidden="true" />
<IconFaceSmile size="xl" color="var(--color-metro)" aria-hidden="true" />
</>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
<span class="hds-icon hds-icon--face-smile hds-icon--size-xs" style="color: var(--color-bus)" aria-hidden="true"></span>
<span class="hds-icon hds-icon--face-smile hds-icon--size-s" style="color: var(--color-brick)" aria-hidden="true"></span>
<span class="hds-icon hds-icon--face-smile hds-icon--size-m" style="color: var(--color-coat-of-arms)" aria-hidden="true"></span>
<span class="hds-icon hds-icon--face-smile hds-icon--size-l" style="color: var(--color-tram)" aria-hidden="true"></span>
<span class="hds-icon hds-icon--face-smile hds-icon--size-xl" style="color: var(--color-metro)" aria-hidden="true"></span>
</div>
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      